import React, { Component } from "react";
import SiderDemo from "./Drawer";


export default class Dashboard extends Component {
  render() {
    return (
      <>
        <SiderDemo />
      </>
    );
  }
}

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Input, Button, Form, notification } from "antd";
import "./login.css";
export default function Login() {
  notification.config({ duration: 2 });
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);

  async function handleLogin() {
    setDisable(true);
    if (email === "") {
      sendNotification("error", {
        title: "Email",
        msg: "Please enter your email address",
      });
      return;
    }
    if (password === "") {
      sendNotification("error", {
        title: "Password",
        msg: "Please enter your password",
      });
      return;
    }
    let data = {
      email: email.trim(),
      password: password.trim(),
    };
    fetch("https://dldigitag.fascom.com/api/admin/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        let result = await response.json();
        if (response.status !== 200) {
          sendNotification("error", {
            title: "Login",
            msg: result.data,
          });
        }
        if (response.status === 200) {
          document.cookie = `token=${result.accessToken}`;
          history.push("/admin");
          sendNotification("success", {
            title: "Login Successfully",
            msg: result.data,
          });
        }
        setDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setDisable(false);
      });
  }

  const sendNotification = (type, data) => {
    notification[type]({
      message: data.title,
      description: data.msg,
    });
    setDisable(false);
    setEmail("");
    setPassword("");
  };
  console.log("load: ", disable);
  return (
    <div className="container ">
      <div className="form">
        <div className="formHeading">
          <h3>Login</h3>
          <p>Please login with your details</p>
        </div>
        <Divider />
        <Form>
          <div className="formBody">
            <Form.Item
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email",
                },
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input
                className="inputs"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input
                type="password"
                className="inputs"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={disable}
              onClick={handleLogin}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

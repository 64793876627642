import React, { Component } from "react";

export default class Title extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="item">
          <div className="dreamtext">
            Making your dream jeans using recycled fibers, energy, and water.
          </div>
        </div>
      </div>
    );
  }
}

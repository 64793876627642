import React, { useEffect, useState } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import { Table, Tag } from "antd";
import { TeamOutlined, RollbackOutlined } from "@ant-design/icons";
import "./Dashboard.css";
import { readCookie } from "../../utils/readCookie";

const { Header, Content, Sider } = Layout;

const SiderDemo = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [influencer, setInfluencer] = useState([]);
  const [pagination, setPaginattion] = useState(0);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(10);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    fetch(
      `https://dldigitag.fascom.com/api/influencer?skip=${skip}&page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          Authorization: `Bearer ${readCookie("token")}`,
        },
      }
    )
      .then(async (response) => {
        let result = await response.json();
        console.log("result", result);
        if (result) {
          setLoad(false);
          setInfluencer(result.influencer);
          setPaginattion(result.pagination);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  }, [skip, page]);
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <p
          style={{ color: "#1890ff" }}
        >{`${record.firstName} ${record.lastName}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <span style={{ textTransform: "lowercase" }}>
          {record.email && record.email}
        </span>
      ),
    },
    {
      title: "Fabric",
      dataIndex: "fabric",
      key: "fabric",
    },
    {
      title: "Fit",
      dataIndex: "fit",
      key: "fit",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
    },
    {
      title: "Hardware",
      dataIndex: "hardware",
      key: "hardware",
    },
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
    },
    {
      title: "Stitching",
      dataIndex: "stitching",
      key: "stitching",
    },
    {
      title: "Favorite Brands",
      dataIndex: "favoriteBrands",
      key: "favoriteBrands",
      render: (text) => (
        <>
          {text && text.length > 5 ? (
            <Tag color="green" key={text}>
              {text.toUpperCase()}
            </Tag>
          ) : text.length === 0 ? null : (
            <Tag color="red" key={text}>
              {text.toUpperCase()}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Notes",
      dataIndex: "additionalNote",
      key: "additionalNote",
    },
    {
      title: "Additional Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  const handleLogout = () => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location = "/";
  };

  const onShowSizeChange = (current, pageSize) => {
    setPage(pageSize);
  };
  const handleChangePage = (value) => {
    value = value - 1;
    setSkip(value);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width="15%"
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div
          style={{
            height: "32px",
            margin: "16px",
            padding: "5px",
            background: "white",
            objectFit: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "70px" }}
            src="/images/DL1961_logo.png"
            alt="Digitag logo"
          />
        </div>
        <Menu defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            style={{ color: "#54667A" }}
            key="1"
            icon={<TeamOutlined />}
          >
            Influencers
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ paddingLeft: "14px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 style={{ color: "white", fontSize: "18px", fontWeight: "400" }}>
              Dashboard
            </h1>
            <h2 style={{ color: "white" }}>
              Back to Digitag
              {
                <RollbackOutlined
                  onClick={handleLogout}
                  style={{ fontSize: "150%" }}
                />
              }
            </h2>
          </div>
        </Header>
        <Content
          style={{
            padding: "0 10px 0 10px",
            width: "auto",
            backgroundColor: "#edf1f5",
          }}
        >
          <Breadcrumb style={{ padding: "5px 0" }}>
            <Breadcrumb.Item style={{ fontSize: "20px", fontWeight: "800" }}>
              Influencers Details
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              background: "#ffffff",
              width: "100% !important",
              padding: "20px 20px 0 20px",
              borderRadius: "10px",
            }}
          >
            <Table
              loading={load}
              rowKey={(record) => record.id}
              style={{
                textTransform: "capitalize",
              }}
              bordered="true"
              scroll={{ x: "50%" }}
              columns={columns}
              dataSource={influencer}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "15", "20"],
                defaultCurrent:
                  pagination && pagination.current ? pagination.current : 1,
                defaultPageSize:
                  pagination && pagination.perPage ? pagination.perPage : 10,
                total: pagination && pagination.count ? pagination.count : null,
                onShowSizeChange: onShowSizeChange,
                pageSize:
                  pagination && pagination.perPage ? pagination.perPage : 5,
                onChange: handleChangePage,
              }}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SiderDemo;

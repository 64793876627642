import React, { useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import LoadingOverlay from "react-loading-overlay";
import { Row, Button, Select, message, Form, Input } from "antd";
import axios from "axios";

import Intro from "./Intro";
import Title from "./Title";
import Banner from "./Banner";
import "@brainhubeu/react-carousel/lib/style.css";
import "./styles-new.css";

const { TextArea } = Input;
const { Option } = Select;

const styles = {
  slide3: {
    height: "100vh",
    width: "100vw",
  },
};

export default function Men(props) {
  const [index] = React.useState(0);
  const [loader, setloader] = React.useState(false);

  ///For Inseam Selection
  const [inseam, setInseam] = React.useState("");
  const [size, setSize] = React.useState("");
  const [value, setValue] = React.useState(0);

  const onChange = (val) => {
    setValue(val);
  };
  ///For FitSlider
  const [fit_selection2, setFitSelection2] = React.useState("");
  useEffect(() => {}, [fit_selection2]);

  ///For FitSelection
  const [fit_selection, setFitSelection] = React.useState("");
  useEffect(() => {}, [fit_selection]);

  ///For Wash Selection
  const [wash_selection, setWashSelection] = React.useState("");
  const [selected, setSelected] = React.useState(false);

  useEffect(() => {}, [wash_selection]);

  ///For Hardware Selection
  const [hardware_selection, setHardwareSelection] = React.useState("");
  useEffect(() => {}, [hardware_selection]);

  ///For Stitching Selection
  const [stitch_selection, setStitching_Selection] = React.useState("");
  useEffect(() => {}, [stitch_selection]);

  ///For Additional Notes
  const [Add_notes, setAdditional_notes] = React.useState({
    add_notes: "",
  });
  const handleChangeAdditional_notes = (e) => {
    const additional_notes = e.target.value;
    setAdditional_notes((prevState) => ({
      ...prevState,
      add_notes: additional_notes,
    }));
  };

  ///For Fabrics
  const [fabric, setFabric] = React.useState(50);
  ///For Fabrics
  const [fabricName, setFabricName] = React.useState("");

  const onChangeFabric = (e) => {
    setFabric(e.target.value);
    if (fabric >= 1 && fabric <= 25) {
      setFabricName("Stretch");
    } else if (fabric > 25 && fabric <= 70) {
      setFabricName("Medium Stretch");
    } else {
      setFabricName("Rigid");
    }
  };

  ///For UserForm
  const [fname, setFname] = React.useState({
    firstName: "",
  });
  const [lname, setLname] = React.useState({
    lastName: "",
  });
  const [favourite_brands, setfavouriteBrand] = React.useState({
    FavBrand: "",
  });
  const [email, setEmail] = React.useState({
    Emai: "",
  });
  const [notes, setNotes] = React.useState({
    Note: "",
  });

  const handleKeyUpfname = (e) => {
    const firstname = e.target.value;
    setFname((prevState) => ({
      ...prevState,
      firstName: firstname,
    }));
    console.log(fname);
  };

    const handleKeyUplname = (e) => {
    const lastname = e.target.value;
    setLname((prevState) => ({
      ...prevState,
      lastName: lastname,
    }));
  };

  const handleKeyUpfavourite_brands = (e) => {
    const favouriteBrand = e.target.value;
    setfavouriteBrand((prevState) => ({
      ...prevState,
      FavBrand: favouriteBrand,
    }));
  };

  const handleKeyUpemail = (e) => {
    const email_ = e.target.value;
    setEmail((prevState) => ({
      ...prevState,
      Emai: email_,
    }));
  };

  const handleKeyUpnotes = (e) => {
    const notes_ = e.target.value;
    setNotes((prevState) => ({
      ...prevState,
      Note: notes_,
    }));
  };

  const formSubmission = async () => {
    // if (fabric === "") {
    //   fabric_name = "50";
    // }

    if (
      fname.firstName === "" ||
      lname.lastName === "" ||
      email.Email === "" ||
      stitch_selection === "" ||
      wash_selection === "" ||
      hardware_selection === "" ||
      fit_selection === "" ||
      inseam === "" ||
      fabric === ""
    ) {
      message.error("Form can not be submit. Please fill all fields");

      return false;
    } else {
      setloader(true);

      axios
        .post(
          "https://dldigitag.fascom.com/api/influencer/add",
          {
            firstName: fname.firstName,
            lastName: lname.lastName,
            email: email.Emai,
            favoriteBrands: favourite_brands.FavBrand,
            fit: fit_selection,
            inseam: inseam,
            wash:  wash_selection,
            hem: " ",
            fabric: fabricName,
            hardware: hardware_selection,
            stitching: stitch_selection,
            notes: notes.Note,
            additionalNote: Add_notes.add_notes,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer token",
            },
          }
        )
        .then((response) => {
          setloader(false);
          console.log(JSON.stringify(response.data));
          message.success("Form Submitted Successfully!");
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            const err = JSON.stringify(error?.response?.data?.data);
            setloader(false);
            message.error(err);
          } else if (error.request) {
            const err = JSON.stringify(error?.response?.data?.data);
            setloader(false);
            message.error(err);
          }
        });
    }
  };
  const [form] = Form.useForm();
  return (
    <>
      <div>
        <SwipeableViews
          axis="y"
          index={index}
          // onChangeIndex={handleStepChange}
          enableMouseEvents
          containerStyle={{
            width: "100vw",
            height: "100vh",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <div style={Object.assign({}, styles.slide3)} key="intro">
            <Intro />
          </div>
          <div style={Object.assign({}, styles.slide3)} key="title">
            <Title />
          </div>
          <div style={Object.assign({}, styles.slide3)} title="banner">
            <Banner />
          </div>

          {/* UserForm */}
          <div style={Object.assign({}, styles.slide3)} title="userform">
            <div className="wrapper">
              <div className="item flex-form">
                <h3>Profile</h3>
                <div>
                  <Form form={form}>
                    <ul className="flex-outer">
                      <li>
                        <Form.Item
                          onChange={(e) => handleKeyUpfname(e)}
                          name="fname"
                          rules={[
                            {
                              required: true,
                              message: "Please enter First Name.",
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]*$/),
                              message: "Numbers not allowed",
                            },
                          ]}
                        >
                          <Input value={fname} placeholder="First Name" />
                        </Form.Item>
                      </li>
                      <li>
                        <Form.Item
                          onChange={(e) => handleKeyUplname(e)}
                          name="lname"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Last Name.",
                            },
                            {
                              message: "Numbers not allowed",
                            },
                          ]}
                        >
                          <Input value={lname} placeholder="Last Name" />
                        </Form.Item>
                      </li>
                      <li>
                        <Form.Item
                          onChange={(e) => handleKeyUpemail(e)}
                          name="email"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              type: "email",
                              required: true,
                              message: "Please enter Valid Email",
                            },
                            {
                              message: "Numbers not allowed",
                            },
                          ]}
                        >
                          <Input value={email} placeholder="Enter Email" />
                        </Form.Item>
                      </li>
                    </ul>
                    <ul className="flex-outer">
                      <li>
                        <Form.Item
                          onChange={(e) => handleKeyUpfavourite_brands(e)}
                          name="favourtie_brand"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea
                            value={favourite_brands}
                            placeholder="Favorite Brands"
                          />
                        </Form.Item>
                      </li>

                      <li>
                        <Form.Item
                          onChange={(e) => handleKeyUpnotes(e)}
                          name="notes"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea value={notes} placeholder="Notes" />
                        </Form.Item>
                      </li>
                    </ul>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          {/* Fot Fit Slider  */}
          <div style={Object.assign({}, styles.slide3)} key="fitSelection2">
            <div className="item fit">
              <h3>Fit</h3>
             
                  <div
                    className={`${"fit-container"} ${
                      fit_selection === "Nick Slim" && "selected-button"
                    } `}
                  >
                    <Button
                      onClick={() => {
                        setFitSelection("Nick Slim");
                      }}
                    >
                      Nick Slim
                    </Button>
                    <img src="/images/nick-slim.jpg" alt="Nick Slim" />
                  </div>
                  <div
                    className={`${"fit-container"} ${
                      fit_selection === "Cooper Tapered" && "selected-button"
                    } `}
                  >
                    <Button
                      onClick={() => {
                        setFitSelection("Cooper Tapered");
                      }}
                    >
                      Cooper Tapered
                    </Button>

                    <img
                      src=" /images/cooper-Tapered.jpg"
                      alt="Cooper Tapered"
                    />
                  </div>

                  <div
                    className={`${"fit-container"} ${
                      fit_selection === "Russell Slim Straight" && "selected-button"
                    } `}
                  >
                    <Button
                      onClick={() => {
                        setFitSelection("Russell Slim Straight");
                      }}
                    >
                      Russell Slim Straight
                    </Button>

                    <img
                      src="/images/russell-slim-straight.jpg"
                      alt="Russell Slim Straight"
                    />
                  </div>
                  <div
                    className={`${"fit-container"} ${
                      fit_selection === "Noah Straight" && "selected-button"
                    } `}
                  >
                    <Button
                      onClick={() => {
                        setFitSelection("Noah Straight");
                      }}
                    >
                      Noah Straight
                    </Button>

                    <img
                      src="/images/noah-straight.jpg"
                      alt="Noah Straight"
                    />
                  </div>
                  <div
                    className={`${"fit-container"} ${
                      fit_selection === "Glenn Wide Leg" && "selected-button"
                    } `}
                  >
                    <Button
                      onClick={() => {
                        setFitSelection("Glenn Wide Leg");
                      }}
                    >
                      Glenn Wide Leg
                    </Button>

                    <img src="/images/Glenn-wide-leg.jpg" alt="Glenn Wide Leg" />
                  </div>
                              {/* ]} */}
                {/* plugins={[
                  "arrows",
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 5,
                    },
                  },
                ]} */}
              {/* /> */}

              {/* <Carousel
                plugins={[
                  "arrows",

                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 3,
                    },
                  },
                ]}
              ></Carousel> */}
            </div>
          </div>

          {/* For Size Selection */}
          <div style={Object.assign({}, styles.slide3)} key="sizeSelection">
            <div className="wrapper">
              <div className="item inseam">
                <h3>Size</h3>

                <div className="inseam-selector">
                  <Select
                    onFocus={(e) => e.stopPropagation()}
                    size="large"
                    onChange={(value) => setSize(value)}
                    style={{
                      width: 400,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    placeholder="Size"
                    optionFilterProp="children"
                    // onClick={(e)=>setInseamSelection(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="28">28</Option>
                    <Option value="29">29</Option>
                    <Option value="30">30</Option>
                    <Option value="31">31</Option>
                    <Option value="32">32</Option>
                    <Option value="33">33</Option>
                    <Option value="34">34</Option>
                    <Option value="35">35</Option>
                    <Option value="36">36</Option>
                    <Option value="38">38</Option>
                    <Option value="40">40</Option>
                    <Option value="42">42</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* For Inseam Selection */}
          <div style={Object.assign({}, styles.slide3)} key="inseamSelection">
            <div className="wrapper">
              <div className="item inseam">
                <h3>Inseam</h3>

                <div className="inseam-selector">
                  <Select
                    onFocus={(e) => e.stopPropagation()}
                    size="large"
                    onChange={(value) => setInseam(value)}
                    style={{
                      width: 400,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    placeholder="Inseam"
                    optionFilterProp="children"
                    // onClick={(e)=>setInseamSelection(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="30">30</Option>
                    <Option value="32">32</Option>
                    <Option value="34">34</Option>
                    <Option value="36">36</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }} key="wash">
            <div className="wrapper">
              <div className="item wash">
                <h3>Wash</h3>
                <div style={{ display: "flex" }}>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "White"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("White")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash1.png" alt="white" />
                      <span>White</span>
                    </Row>
                    {wash_selection === "White" && (
                      <span>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Selcet
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Light Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={() => {
                      setWashSelection("Light Indigo");
                    }}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash2.png" alt="Light indigo" />
                      <span>Light Indigo</span>
                    </Row>
                    {wash_selection === "Light Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Selcet
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Mid Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={() => {
                      setWashSelection("Mid Indigo");
                    }}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash3.png" alt="Mid indigo" />
                      <span>Mid Indigo</span>
                    </Row>
                    {wash_selection === "Mid Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Dark Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => {
                      setWashSelection("Dark Indigo");
                    }}
                  >
                    <div
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash4.png" alt="Dark indigo" />
                      <span>Dark Indigo</span>
                    </div>
                    {wash_selection === "Dark Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Washed Black"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("Washed Black")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash5.png" alt="washed black" />
                      <span>Washed Black</span>
                    </Row>
                    {wash_selection === "Washed Black" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Solid Black"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("Solid Black")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash6.png" alt="solid black" />
                      <span>Solid Black</span>
                    </Row>
                    {wash_selection === "Solid Black" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Fabric Selection */}
          <div style={Object.assign({}, styles.slide3)} key="fabric">
            <div className="wrapper">
              <div className="item fabric">
                <h3>Fabric</h3>
                <div className="inseam-selector">
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p className="stretch">Stretch</p>
                    <input
                      type="range"
                      min="1"
                      max="100"
                      onChange={(e) => onChangeFabric(e)}
                      value={fabric}
                      className="slider"
                      id="range"
                    />
                    <p className="rigid">Rigid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Hardware Selection */}
          <div style={Object.assign({}, styles.slide3)} key="hardware">
            <div className="wrapper">
              <div className="item hardware">
                <h3>Hardware</h3>

                <div className="inseam-selector">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    //   alignItems: "center",
                    }}
                  >
                    <div
                    style={{width:"50%"}}
                      className={`${"hardware-container"} ${
                        hardware_selection === "Brushed Silver" &&
                        "hardware-container selected-hardware"
                      } `}
                    >
                      <img src="/images/btn-silver-men.png" alt="brushed silver" />
                      <Button
                        onClick={(e) => setHardwareSelection("Brushed Silver")}
                      >
                        Brushed Silver
                      </Button>
                    </div>
                    <div
                      style={{width:"50%"}}
                      className={`${"hardware-container"} ${
                        hardware_selection === "Brushed Gold" &&
                        "hardware-container selected-hardware"
                      } `}
                    >
                      <img src="/images/btn-black-men.png" alt="black" />
                      <Button
                        onClick={(e) => setHardwareSelection("Black")}>
                       Black
                      </Button>
                    </div>
                     </div>
                </div>

              </div>
            </div>
          </div>

          {/* For Stitching Selection */}
          <div style={Object.assign({}, styles.slide3)} key="Stitching">
            <div className="wrapper">
              <div className="item stitching">
                <h3>Stitching</h3>

                <div className="inseam-selector">
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`${"stitching-container"} ${
                        stitch_selection === "Tonal" &&
                        "stitching-container selected-tonal"
                      } `}
                    >
                      <div
                        onClick={(e) => setStitching_Selection("Tonal")}
                        className="sth-1"
                      >
                        <span>Tonal</span>
                      </div>
                    </div>
                    <div
                      className={`${"stitching-container"} ${
                        stitch_selection === "Contrast" &&
                        "stitching-container selected-contrast"
                      } `}
                    >
                      <div
                        onClick={(e) => setStitching_Selection("Contrast")}
                        className="sth-1 sth-2"
                      >
                        <span>Contrast</span>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

            {/* For Additional Notes */}
          <div style={Object.assign({}, styles.slide3)} key="Additional Notes">
            <div className="wrapper">
              <div className="item additional-notes">
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ minHeight: "100vh" }}
                >
                  <TextArea
                    placeholder="Additional Notes"
                    bordered
                    rows="10"
                    allowClear
                    onChange={(e) => handleChangeAdditional_notes(e)}
                  />
                </Row>
              </div>
            </div>
          </div>

          {/* For Form Submission */}
          <div style={Object.assign({}, styles.slide3)} key="Form">
            <LoadingOverlay active={loader} spinner>
              <div className="wrapper">
                <div className="item formsubmit">
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ minHeight: "100vh" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className="table">
                        <div>Name</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {fname.firstName + " " + lname.lastName}
                        </div>

                        <div>Fit</div>
                        <div>{fit_selection}</div>

                        <div>Size</div>
                        <div>{size}</div>

                        <div>Inseam</div>
                        <div>{inseam}</div>

                        <div>Wash</div>
                        <div>{wash_selection}</div>

                        <div>Fabric</div>
                        <div>{fabricName}</div>

                        <div>Hardware</div>
                        <div>{hardware_selection}</div>

                        <div>Stitching</div>
                        <div>{stitch_selection}</div>

                        <div>Notes</div>
                        <div>{notes.Note}</div>
                      </div>

                      <div className="submit-btndiv">
                        <Button onClick={() => formSubmission()}>Submit</Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </SwipeableViews>
      </div>
    </>
  );
}

import React, { Component } from "react";

export default class Intro extends Component {
  render() {
    return (
      <>
      <div className="wrapper">
      <div className="item box">
        <img  style={{width:"150px"}} src="/images/DL1961_logo.png" alt="Digitag logo"/>
        <h2>/DIGITAG_PROGRAM</h2>
      </div>
      </div>
      </>
    );
  }
}

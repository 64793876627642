import React, { Component } from "react";

export default class Banner extends Component {
  render() {
    return (
      <>
        <div className="wrapper">
          <div className="item vid">
            <div className="vid-container">
              <video
                className="myVideo"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/water.mp4?v=1637073261"
              />

              <video
                className="myVideom"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/water-m_c73dd62d-91d7-470d-8360-a28cb2e3fff2.mp4?v=1637295757"
              />
              <div>
                A typical pair of jeans takes 1500 gallons of water to make.
                Ours take less than 10, thanks to ultra-absorbent fibers and
                waterless technologies.
              </div>
            </div>

            <div className="vid-container">
              <video
                className="myVideo"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/recycled.mp4?v=1637073261"
              />
              <video
                className="myVideom"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/recycled-m_2e6d9856-cf39-467f-bba5-b43aabdc8b70.mp4?v=1637295757"
              />
              <div>
                We’ve partnered with Recover™ to recycle excess textile waste
                from around the world into new, high-tech fibers.
              </div>
            </div>

            <div className="vid-container">
              <video
                className="myVideo"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/solar.mp4?v=1637073261"
              />
              <video
                className="myVideom"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/solar-m_44c6fe0c-d76e-43ef-8156-8aacdf27903f.mp4?v=1637295757"
              />
              <div>
                Our self-generating power plant uses solar panels and heat
                recovery to power itself.
              </div>
            </div>

            <div className="vid-container">
              <video
                className="myVideo"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/laser.mp4?v=1637073261"
              />
              <video
                className="myVideom"
                playsInline
                autoPlay
                loop
                muted
                src="//cdn.shopify.com/s/files/1/2397/3099/files/laser-m_99a6a701-9cec-46ca-889a-76efa725d5b0.mp4?v=1637295758"
              />
              <div>
                98% of the water we use gets treated and recycled in our
                in-house plant.
              </div>
            </div>
            <div className="arrow-dw">
              {<img src="/images/arrow-dw.png" alt="down arrow" />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { readCookie } from "./readCookie";
import { useJwt } from "react-jwt";
function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = readCookie("token");
  const { isExpired } = useJwt(isAuthenticated);
  useEffect(() => {
    console.log("isExpired", isExpired);
    if (isExpired) {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      window.location = "/";
    }
  });
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="login" />
      }
    />
  );
}

export default ProtectedRoute;

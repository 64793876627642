import React, { useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import LoadingOverlay from "react-loading-overlay";
import { Row, Button, Select, message, Form, Input } from "antd";
import axios from "axios";

import Intro from "./Intro";
import Title from "./Title";
import Banner from "./Banner";

import "./styles-new.css";

const { TextArea } = Input;
const { Option } = Select;

const styles = {
  slide3: {
    height: "100vh",
    width: "100vw",
  },
};

export default function SwipeViewGinevra(props) {
  const [index] = React.useState(0);
  const [loader, setloader] = React.useState(false);

  ///For Inseam Selection
  const [inseam, setInseam] = React.useState("");

  ///For FitSelection
  const [fit_selection, setFitSelection] = React.useState("");
  useEffect(() => {}, [fit_selection]);

  ///For Wash Selection
  const [wash_selection, setWashSelection] = React.useState("");
  const [selected, setSelected] = React.useState(false);

  useEffect(() => {}, [wash_selection]);

  ///For Hardware Selection
  const [hardware_selection, setHardwareSelection] = React.useState("");
  useEffect(() => {}, [hardware_selection]);

  ///For Stitching Selection
  const [stitch_selection, setStitching_Selection] = React.useState("");
  useEffect(() => {}, [stitch_selection]);

  ///For Hem Selection
  const [hem_selection, sethem_Selection] = React.useState(0);
  useEffect(() => {}, [hem_selection]);

  ///For Additional Notes
  const [Add_notes, setAdditional_notes] = React.useState({
    add_notes: "",
  });
  const handleChangeAdditional_notes = (e) => {
    const additional_notes = e.target.value;
    setAdditional_notes((prevState) => ({
      ...prevState,
      add_notes: additional_notes,
    }));
  };

  ///For Fabrics
  const [fabric, setFabric] = React.useState(50);
  ///For Fabrics
  const [fabricName, setFabricName] = React.useState("");

  const onChangeFabric = (e) => {
    setFabric(e.target.value);
    if (fabric >= 1 && fabric <= 25) {
      setFabricName("Stretch");
    } else if (fabric > 25 && fabric <= 70) {
      setFabricName("Medium Stretch");
    } else {
      setFabricName("Rigid");
    }
  };

  ///For UserForm
  const [fname, setFname] = React.useState({
    firstname: "",
  });
  const [lname, setLname] = React.useState({
    lastName: "",
  });
  const [favourite_brands, setfavouriteBrand] = React.useState({
    FavBrand: "",
  });
  const [email, setEmail] = React.useState({
    Emai: "",
  });
  const [notes, setNotes] = React.useState({
    Note: "",
  });

  const handleKeyUpfname = (e) => {
    const firstname = e.target.value;
    setFname((prevState) => ({
      ...prevState,
      firstName: firstname,
    }));
  };
  const handleKeyUplname = (e) => {
    const lastname = e.target.value;
    setLname((prevState) => ({
      ...prevState,
      lastName: lastname,
    }));
  };
  const handleKeyUpfavourite_brands = (e) => {
    const favouriteBrand = e.target.value;
    setfavouriteBrand((prevState) => ({
      ...prevState,
      FavBrand: favouriteBrand,
    }));
  };

  const handleKeyUpemail = (e) => {
    const email_ = e.target.value;
    setEmail((prevState) => ({
      ...prevState,
      Emai: email_,
    }));
  };

  const handleKeyUpnotes = (e) => {
    const notes_ = e.target.value;
    setNotes((prevState) => ({
      ...prevState,
      Note: notes_,
    }));
  };

  const formSubmission = async () => {
    // if (fabric === "") {
    //   fabric_name = "50";
    // }

    if (
      fname.firstName === "" ||
      lname.lastName === "" ||
      email.Email === "" ||
      hem_selection === "" ||
      stitch_selection === "" ||
      wash_selection === "" ||
      hardware_selection === "" ||
      fit_selection === "" ||
      inseam === "" ||
      fabric === ""
    ) {
      message.error("Form can not be submit. Please fill all fields");

      return false;
    } else {
      setloader(true);

      axios
        .post(
          "https://dldigitag.fascom.com/api/influencer/add",
          {
            firstName: fname.firstName,
            lastName: lname.lastName,
            email: email.Emai,
            favoriteBrands: favourite_brands.FavBrand,
            fit: fit_selection,
            inseam: inseam,
            wash: wash_selection,
            fabric: fabricName,
            hardware: hardware_selection,
            stitching: stitch_selection,
            hem: hem_selection,
            notes: notes.Note,
            additionalNote: Add_notes.add_notes,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer token",
            },
          }
        )
        .then((response) => {
          setloader(false);
          console.log(JSON.stringify(response.data));
          message.success("Form Submitted Successfully!");
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            const err = JSON.stringify(error.response.data.data);
            setloader(false);
            message.error(err);
          } else if (error.request) {
            const err = JSON.stringify(error.response.data.data);
            setloader(false);
            message.error(err);
          }
        });
    }
  };
  const [form] = Form.useForm();
  return (
    <>
      <div>
        <SwipeableViews
          axis="y"
          index={index}
          // onChangeIndex={handleStepChange}
          enableMouseEvents
          containerStyle={{
            width: "100%",
            height: "100vh",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <div style={Object.assign({}, styles.slide3)} key="intro">
            <Intro />
          </div>
          <div style={Object.assign({}, styles.slide3)} key="title">
            <Title />
          </div>
          <div style={Object.assign({}, styles.slide3)} title="banner">
            <Banner />
          </div>

          {/* UserForm */}
          <div style={Object.assign({}, styles.slide3)} title="userform">
            <div className="wrapper">
              <div className="item flex-form">
                <h3>Profile</h3>
                <Form form={form}>
                  <ul className="flex-outer">
                    <li>
                      <Form.Item
                        onChange={(e) => handleKeyUpfname(e)}
                        name="fname"
                        rules={[
                          {
                            required: true,
                            message: "Please enter First Name.",
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z ]*$/),
                            message: "Numbers not allowed",
                          },
                        ]}
                      >
                        <Input value={fname} placeholder="First Name" />
                      </Form.Item>
                    </li>
                    <li>
                      <Form.Item
                        onChange={(e) => handleKeyUplname(e)}
                        name="lname"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter Last Name.",
                          },
                          {
                            message: "Numbers not allowed",
                          },
                        ]}
                      >
                        <Input value={lname} placeholder="Last Name" />
                      </Form.Item>
                    </li>
                    <li>
                      <Form.Item
                        onChange={(e) => handleKeyUpemail(e)}
                        name="email"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please enter Valid Email",
                          },
                          {
                            message: "Numbers not allowed",
                          },
                        ]}
                      >
                        <Input value={email} placeholder="Enter Email" />
                      </Form.Item>
                    </li>
                  </ul>
                  <ul className="flex-outer">
                    <li>
                      <Form.Item
                        onChange={(e) => handleKeyUpfavourite_brands(e)}
                        name="favourtie_brand"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea
                          value={favourite_brands}
                          placeholder="Favorite Brands"
                        />
                      </Form.Item>
                    </li>

                    <li>
                      <Form.Item
                        onChange={(e) => handleKeyUpnotes(e)}
                        name="notes"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea value={notes} placeholder="Notes" />
                      </Form.Item>
                    </li>
                  </ul>
                </Form>
              </div>
            </div>
          </div>

          {/* For Fit Selection */}
          <div style={Object.assign({}, styles.slide3)} key="fitSelection">
            <div className="wrapper">
              <div className="item fit">
                <h3>Fit</h3>

                <div
                  className={`${"fit-container"} ${
                    fit_selection === "Sydney Girlfriend" &&
                    "fit-container selected-button"
                  } `}
                >
                  <Button
                    onClick={() => {
                      setFitSelection("Sydney Girlfriend");
                    }}
                  >
                    Sydney Girlfriend
                  </Button>
                  <img
                    src="/images/Sydney_girlfreind-v1.jpg"
                    alt="sydney girlfriend"
                  />
                </div>
                <div
                  className={`${"fit-container"} ${
                    fit_selection === " Emily Straight" &&
                    "fit-container selected-button"
                  } `}
                >
                  <Button
                    onClick={() => {
                      setFitSelection(" Emily Straight");
                    }}
                  >
                    Emily Straight
                  </Button>

                  <img
                    src=" /images/Emily_Straight_V1.jpg"
                    alt="Emily straight"
                  />
                </div>
                <div
                  className={`${"fit-container"} ${
                    fit_selection === "Patti Straight" &&
                    "fit-container selected-button"
                  } `}
                >
                  <Button
                    onClick={() => {
                      setFitSelection("Patti Straight");
                    }}
                  >
                    Patti Straight
                  </Button>

                  <img
                    src="/images/Patti_Straight_V1.jpg"
                    alt="Patti Straight"
                  />
                </div>

                <div
                  className={`${"fit-container"} ${
                    fit_selection === "Hepburn Wide Leg" &&
                    "fit-container selected-button"
                  } `}
                >
                  <Button
                    onClick={() => {
                      setFitSelection("Hepburn Wide Leg");
                    }}
                  >
                    Hepburn Wide Leg
                  </Button>

                  <img
                    src="/images/Hepburn_WideLeg-V1.jpg"
                    alt="Hepburn Wide Leg"
                  />
                </div>
                <div
                  className={`${"fit-container"} ${
                    fit_selection === " Bridget Boot Cut" &&
                    "fit-container selected-button"
                  } `}
                >
                  <Button
                    onClick={() => {
                      setFitSelection(" Bridget Boot Cut");
                    }}
                  >
                    Bridget Boot Cut
                  </Button>

                  <img
                    src="/images/Bridget_Boot_Cut-V1.jpg"
                    alt=" Bridget Boot Cut"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* For Inseam Selection */}
          <div style={Object.assign({}, styles.slide3)} key="inseamSelection">
            <div className="wrapper">
              <div className="item inseam">
                <h3>Inseam</h3>

                <div className="inseam-selector">
                  <Select
                    onFocus={(e) => e.stopPropagation()}
                    size="large"
                    onChange={(value) => setInseam(value)}
                    style={{
                      width: 400,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    placeholder="Select Inseam"
                    optionFilterProp="children"
                    // onClick={(e)=>setInseamSelection(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="26">26</Option>
                    <Option value="27">27</Option>
                    <Option value="28">28</Option>
                    <Option value="29">29</Option>
                    <Option value="30">30</Option>
                    <Option value="31">31</Option>
                    <Option value="32">32</Option>
                    <Option value="33"> 33</Option>
                    <Option value="34">34</Option>
                    <Option value="35">35</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }} key="wash">
            <div className="wrapper">
              <div className="item wash">
                <h3>Wash</h3>
                <div style={{ display: "flex" }}>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "White"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("White")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash1.png" alt="white" />
                      <span>White</span>
                    </Row>
                    {wash_selection === "White" && (
                      <span>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Selcet
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Light Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={() => {
                      setWashSelection("Light Indigo");
                    }}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash2.png" alt="Light indigo" />
                      <span>Light Indigo</span>
                    </Row>
                    {wash_selection === "Light Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Selcet
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Mid Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={() => {
                      setWashSelection("Mid Indigo");
                    }}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash3.png" alt="Mid indigo" />
                      <span>Mid Indigo</span>
                    </Row>
                    {wash_selection === "Mid Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Dark Indigo"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => {
                      setWashSelection("Dark Indigo");
                    }}
                  >
                    <div
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash4.png" alt="Dark indigo" />
                      <span>Dark Indigo</span>
                    </div>
                    {wash_selection === "Dark Indigo" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Washed Black"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("Washed Black")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash5.png" alt="washed black" />
                      <span>Washed Black</span>
                    </Row>
                    {wash_selection === "Washed Black" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${"wash-container"} ${
                      wash_selection === "Solid Black"
                        ? "wash-container "
                        : selected && "unSelected-wash-2"
                    } `}
                    onClick={(e) => setWashSelection("Solid Black")}
                  >
                    <Row
                      onClick={() => setSelected(false)}
                      type="flex"
                      justify="center"
                      align="middle"
                    >
                      <img src="/images/wash6.png" alt="solid black" />
                      <span>Solid Black</span>
                    </Row>
                    {wash_selection === "Solid Black" && (
                      <span style={{ marginTop: "20px", color: "black" }}>
                        {!selected && (
                          <button
                            onClick={() => setSelected(true)}
                            className="wash-select-button"
                          >
                            Select
                          </button>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Fabric Selection */}
          <div style={Object.assign({}, styles.slide3)} key="fabric">
            <div className="wrapper">
              <div className="item fabric">
                <h3>Fabric</h3>

                <div className="inseam-selector">
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p className="stretch">Stretch</p>
                    <input
                      type="range"
                      min="1"
                      max="100"
                      onChange={(e) => onChangeFabric(e)}
                      value={fabric}
                      className="slider"
                      id="range"
                    />
                    <p className="rigid">Rigid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Hardware Selection */}
          <div style={Object.assign({}, styles.slide3)} key="hardware">
            <div className="item hardware">
              <h3>Hardware</h3>

              <div className="inseam-selector">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={`${"hardware-container"} ${
                      hardware_selection === "Brushed Silver" &&
                      "hardware-container selected-hardware"
                    } `}
                  >
                    <img src="/images/btn_silver.png" alt="brushed silver" />
                    <Button
                      onClick={(e) => setHardwareSelection("Brushed Silver")}
                    >
                      Brushed Silver
                    </Button>
                  </div>
                  <div
                    className={`${"hardware-container"} ${
                      hardware_selection === "Brushed Gold" &&
                      "hardware-container selected-hardware"
                    } `}
                  >
                    <img src="/images/btn_gold.png" alt="brushed gold" />
                    <Button
                      onClick={(e) => setHardwareSelection("Brushed Gold")}
                    >
                      Brushed Gold
                    </Button>
                  </div>
                  <div
                    className={`${"hardware-container"} ${
                      hardware_selection === "Mate Rose Gold" &&
                      "hardware-container selected-hardware"
                    } `}
                  >
                    <img src="/images/btn_pinkgold.png" alt="mate rose gold" />
                    <Button
                      onClick={(e) => setHardwareSelection("Mate Rose Gold")}
                    >
                      Mate Rose Gold
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Stitching Selection */}
          <div style={Object.assign({}, styles.slide3)} key="Stitching">
            <div className="wrapper">
              <div className="item stitching">
                <h3>Stitching</h3>

                <div className="inseam-selector">
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`${"stitching-container"} ${
                        stitch_selection === "Tonal" &&
                        "stitching-container selected-tonal"
                      } `}
                    >
                      <div
                        onClick={(e) => setStitching_Selection("Tonal")}
                        className="sth-1"
                      >
                        <span>Tonal</span>
                      </div>
                    </div>
                    <div
                      className={`${"stitching-container"} ${
                        stitch_selection === "Contrast" &&
                        "stitching-container selected-contrast"
                      } `}
                    >
                      <div
                        onClick={(e) => setStitching_Selection("Contrast")}
                        className="sth-1 sth-2"
                      >
                        <span>Contrast</span>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* For Hem Selection */}
          <div style={Object.assign({}, styles.slide3)} key="hem selection">
            <div className="wrapper">
              <div className="item hem">
                <h3>Hem</h3>
                <div className="inseam-selector">
                  <div className="hems">
                    <div
                      className={`${"hem-container "} ${
                        hem_selection === "Clean" &&
                        "hem-container  selected-hem"
                      } `}
                    >
                      <Button onClick={(e) => sethem_Selection("Clean")}>
                        Clean
                      </Button>
                    </div>
                    <div
                      className={`${"hem-container "} ${
                        hem_selection === "Clean and Grinding" &&
                        "hem-container  selected-hem"
                      } `}
                    >
                      <Button
                        onClick={(e) => sethem_Selection("Clean and Grinding")}
                      >
                        Clean with Grinding
                      </Button>
                    </div>
                    <div
                      className={`${"hem-container "} ${
                        hem_selection === "Raw" && "hem-container  selected-hem"
                      } `}
                    >
                      <Button onClick={(e) => sethem_Selection("Raw")}>
                        Raw
                      </Button>
                    </div>
                    <div className="hem-container">
                      <Button
                        onClick={(e) => sethem_Selection("Supper Shredded")}
                      >
                        Supper Shredded
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "50px",
                      width: "100%",
                      borderTop: "0.2px solid #b8bbbe",
                    }}
                  >
                    <img src="/images/hem-bg1.png" alt="Hem" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For Additional Notes */}
          <div style={Object.assign({}, styles.slide3)} key="Additional Notes">
            <div className="wrapper">
              <div className="item additional-notes">
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ minHeight: "100vh", width: "100vw" }}
                >
                  <TextArea
                    placeholder="Additional Notes"
                    bordered
                    rows="10"
                    allowClear
                    onChange={(e) => handleChangeAdditional_notes(e)}
                  />
                </Row>
              </div>
            </div>
          </div>

          {/* For Form Submission */}
          <div style={Object.assign({}, styles.slide3)} key="Form">
            <LoadingOverlay active={loader} spinner>
              <div style={Object.assign({}, styles.slide3)}>
                <div className="wrapper">
                  <div className="item formsubmit">
                    <Row
                      type="flex"
                      justify="center"
                      align="middle"
                      style={{ minHeight: "100vh" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <div className="table">
                          <div>Fit</div>
                          <div>{fit_selection}</div>

                          <div>Inseam</div>
                          <div>{inseam}</div>

                          <div>Wash</div>
                          <div>{wash_selection}</div>

                          <div>Fabric</div>
                          <div>{fabricName}</div>

                          <div>Hardware</div>
                          <div>{hardware_selection}</div>
                          <div>Stitching</div>
                          <div>{stitch_selection}</div>
                          <div>Notes</div>
                          <div>{Add_notes.add_notes}</div>
                        </div>

                        <div className="submit-btndiv">
                          <Button onClick={() => formSubmission()}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </SwipeableViews>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login";
import Swape from "./components/swape-view";

import SwipeViewGinevra from "./components/swipe-View-ginevra";
import ProtectedRoute from "./utils/protected.route";
import { readCookie } from "./utils/readCookie";
import Men from "./components/Men"

function App() {
  const [token] = useState(readCookie("token"));
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Swape />
        </Route>
        <Route exact path="/men">
          <Men />
        </Route>
        <Route path="/ginevra">
          <SwipeViewGinevra />
        </Route>
        <Route
          path="/login"
          render={() => {
            if (token) return <Redirect to="admin" />;
            else return <Route exact path="/login" component={Login} />;
          }}
        />
        <ProtectedRoute component={Dashboard} />
      </Switch>
    </div>
  );
}

export default App;
